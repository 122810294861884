<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <router-view/>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Navbar from './components/Navbar.vue'

export default {
  name: 'App',
  // components: {
  //   Navbar
  // }
}
</script>

<style>

html {
  background-color: #f0f1f3;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f1f3;
  /* text-align: center; */
  color: #2c3e50;
  margin-top: 0px;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
}
</style>
