<template>
  <section class="section footer">
    <div class="w-layout-blockcontainer container w-container">
      <div class="w-layout-grid grid-footer">
        <div class="grid-block w-clearfix">
          <img
            src="@/assets/yhz-logo.png"
            loading="lazy"
            alt=""
            class="footer-brand"
          />
          <div class="footer-copyright">Copyright © 2024<br /></div>
        </div>
        <div class="grid-block footer-center">
          <a href="/solutions"
            aria-current="page"
            class="footer-link w--current"
            >Solutions</a>
            <a href="/applications" class="footer-link">Applications</a>
        </div>
        <div class="grid-block">
          <a href="https://web.facebook.com/wearekofa?_rdc=1&amp;_rdr"
            target="_blank"
            class="social-ico facebook w-inline-block" ></a>
          <a href="https://www.linkedin.com/company/wearekofa"
            target="_blank"
            class="social-ico linkedin w-inline-block" ></a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.section {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
}

.section.footer {
  background-color: #ffffff;
  width: 98%;
  padding-left: 10px;
}

.grid-footer{
  grid-column-gap: 50px;
    grid-row-gap: 50px;
    grid-template-rows: auto;
    grid-template-columns: .5fr 1.75fr .5fr;
}

.w-clearfix:before, .w-clearfix:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
}

img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}
.footer-brand {
    width: 75px;
    height: 20px;
    float: left;
    margin-right: 15px;
}

.footer-copyright {
    float: left;
    opacity: .5;
}

.grid-block.footer-center {
    justify-content: center;
    display: flex;
}

.footer-link {
    color: #000;
    margin-left: 10px;
    margin-right: 10px;
    text-decoration: none;
}

.footer-link.w--current {
    opacity: .5;
}

@import "@/style/home.css";
</style>
