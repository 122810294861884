<template>
  <el-row class="navbar" :gutter="20" style="border-bottom: 1px solid rgba(0, 0, 0, .07);">
    <el-col :span="16" :xs="24" class="logo-title">
      <img src="@/assets/yhz-logo.png" alt="Logo" class="logo" />
      <span class="site-title">No.1 Pile</span>
    </el-col>
    <el-col :lg="8" :md="10" :sm="12" :xs="24" class="menu-col" v-if="!isMobile">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-col"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="1">Solutions</el-menu-item>
        <el-menu-item index="2">About</el-menu-item>
        <el-menu-item index="3">Contact</el-menu-item>
      </el-menu>
    </el-col>
    <el-col :span="24" class="menu-col mobile" v-else>
      <!-- 移动端菜单按钮 -->
      <el-button
        icon="el-icon-menu"
        class="mobile-menu"
        style="position: absolute; right: 10px; top: 10px;"
        @click="toggleDrawer"
      ></el-button>
      <!-- 移动端菜单抽屉 -->
      <el-drawer
        title="Order"
        :visible.sync="drawerVisible"
        :direction="direction"
        custom-class="mobile-drawer"
      >
        <el-menu :default-active="activeIndex" @select="handleSelect">
          <el-menu-item index="1">Solutions</el-menu-item>
        <el-menu-item index="2">About</el-menu-item>
        <el-menu-item index="3">Contact</el-menu-item>
        </el-menu>
      </el-drawer>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '1',
      isMobile: false,
      drawerVisible: false,
      direction: 'ttb',
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.drawerVisible = false; // 选择菜单项后关闭抽屉
    },
    toggleDrawer() {
      this.drawerVisible = !this.drawerVisible;
    },
    checkDevice() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
  mounted() {
    this.checkDevice();
    window.addEventListener('resize', this.checkDevice);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice);
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px;
  margin: 0px !important;
}

.logo-title {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px; /* 根据实际情况调整 */
  border-radius: 25px;
}

.site-title {
  margin-left: 10px;
  font-size: 1rem;
}

.menu-col {
  text-align: right;
}

.el-menu-col {
  float: right;
  background-color: #f0f1f3;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 2px solid #ffa000;
}

.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
  background-color: #f0f1f3;
}

.mobile-menu {
  z-index: 1000; /* 确保按钮在最上层 */
  border: 0;
}

.mobile-menu {
  padding: 10px;
  font-size: 20px;
}

.mobile-menu:hover, .mobile-menu:focus, .mobile-menu:active {
  background: none;
  color: #606266;
}

.mobile-drawer {
  overflow: auto;
  max-height: 150px; /* 根据实际情况调整，以容纳三个菜单项 */
}

.mobile-drawer .el-menu-item{
  padding-left: 24px;
  text-align: left;
  line-height: 40px;
}

@media screen and (max-width: 768px) {
  .el-button {
    background-color: #f0f1f3;
  }

}
</style>