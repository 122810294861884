<template>
  <div class="home-container">
    <Navbar />
    <Carousel />
    <section class="section">
      <div class="w-layout-blockcontainer container w-container">
        <div class="w-layout-grid grid-solutions-about">
          <div class="grid-block solutions">
            <div class="artifact-vertical-line swap-solutions"></div>
            <div class="solutions-content-column">
              <div class="solutions-content-wrapper">
                <div class="content-wrapper">
                  <h2 class="h2">
                    Shanghai Xili Electronic Technology Co., Ltd.
                  </h2>
                </div>
                <p>
                  Shanghai Xili Electronic Technology Co., Ltd. is an enterprise engaged in the research,
                  development and operation of 5G IoT safe power exchange equipment fore-bikes and IoT energy system
                  platform,under the guidance of experts from
                  the Ministry of Environmental Protection and the Ministry of Commerce and invested by Foxconn Group.
                  And participate in the drafting
                  and formulation of relevant national lithium battery standards. Obtained intellectual property
                  rights(about 40 patent certificates and softworks).
                  .<br /><br />“No.1 Pile” intelligent power exchange has created a domestic leading “Internet of Things
                  Energy Sharing Service Platform ”, reconstructing the
                  traditional artificial charging mode into an unattended automatic power-changing system and a
                  zero-cost ladder utilization system, effectively
                  reducing lithium battery pollution and resource waste, achieving green travel
                  and low Carbon, environmental protection, energy saving goals. Realize the intelligentization,
                  standardization and sharing of energy through the Internet of Things and the Internet of Vehicles
                  technology to realize the
                  intelligentization of urban distributed energy.
                </p>
              </div>
            </div>
          </div>
          <div class="breakout-banner solutiions-design">
            <div class="artifact-vertical-line swap-solutions img"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="section top-minus-50">
      <div class="w-layout-blockcontainer container battery-pack w-container">
        <div class="w-layout-grid grid-swap-and-go">
          <div class="solutions-content-column">
            <div class="solutions-swap-cont-wrapper">
              <div class="content-wrapper">
                <h4 class="h4 base-12-5">The No.1 Pile Battery</h4>
                <h1 class="h1">Next-Generation Wireless Energy</h1>
              </div>
              <div class="content-wrapper">
                <p>A cutting edge solution to easy access, on-demand green energy.<br><br>The Kore2 battery is an
                  upgrade of the Kore1 with improved features such as:<br></p>
              </div>
              <p>Our battery advantage</p>
              <div class="count-content-wrapper">
                <h3 class="h3-count">01</h3>
                <div class="count-content-block">
                  <h4 class="h4">Powerful &amp; Portable</h4>
                  <p>Each Kore2 battery is even more powerful than the Kore1 . With 20% more Capacity in the battery
                    pack you get more time on your vehicle; appliances and more , while still being light to carry.</p>
                </div>
              </div>
              <div class="count-content-wrapper">
                <h3 class="h3-count">02</h3>
                <div class="count-content-block">
                  <h4 class="h4">A True Network</h4>
                  <p>Featuring our proprietary Internet of Things (IoT) technology - the Kore2 Network provides
                    up-to-the-moment information on speed, location, state of charge, temperature and host of other
                    variables - to keep you and your assets secure.</p>
                </div>
              </div>
              <div class="count-content-wrapper">
                <h3 class="h3-count">03</h3>
                <div class="count-content-block">
                  <h4 class="h4">Safe</h4>
                  <p>From safety certified cells and battery structures, through to remote temperature monitoring you
                    have access to the safest batteries on the market.</p>
                </div>
              </div>
              <div class="count-content-wrapper">
                <h3 class="h3-count">04</h3>
                <div class="count-content-block">
                  <h4 class="h4">Battery-as-a-Service</h4>
                  <p>Best of all, No.1 Pile's battery access gives you all of the benefits without the upfront cost or the
                    burden of maintenance and disposal.</p>
                </div>
              </div>
              <div class="breakout-header-copy">
                <div>No.1 Pile Battery Pack<br></div>
                <div class="artifact-horizontal-line battery-label"></div>
              </div>
            </div>
          </div>
          <div class="swap-banner">
            <div class="solutions-kore1-block">
              <img src="@/assets/image3.png"
                alt="" sizes="(max-width: 479px) 94vw, (max-width: 991px) 96vw, 100vw"
                class="solutions-battery-img">
              <div class="artifact-horizontal-line battery-stats"></div>
            </div>
            <div class="spec-base-wrapper">
              <div class="w-layout-grid grid-swap-spec">
                <div class="grid-block">
                  <h3 class="spec-title">2.3 kWh</h3>
                  <div class="spec-sub-title">Capacity</div>
                </div>
                <div class="grid-block">
                  <h3 class="spec-title">Lithium Ion</h3>
                  <div class="spec-sub-title">Cells</div>
                </div>
                <div class="grid-block">
                  <h3 class="spec-title">16kg</h3>
                  <div class="spec-sub-title">Weight</div>
                </div>
              </div>
              <div class="artifact-horizontal-line battery-stats"></div>
            </div>
            <div class="spec-base-wrapper base">
              <p>Available through a No.1 Pile Swap &amp; Go subscription and pay-per-swap service.</p>
            </div>
            <div class="artifact-vertical-line battery-img"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="section top-minus-50">
      <div class="w-layout-blockcontainer container w-container">
        <div class="w-layout-grid grid-solutions-tech">
          <div class="grid-block app">
            <div class="content-wrapper">
              <h3 class="h3">Actionable information right at your fingertips through the No.1 Pile mobile and web app.</h3>
            </div>
            <div class="content-wrapper">
              <div>Features</div>
              <div class="count-content-wrapper">
                <h3 class="h3-count">01</h3>
                <div class="count-content-block">
                  <h4 class="h4">Access Options</h4>
                  <p>Manage your Swap &amp; Go access with options to suit your energy applications, usage and budgets.
                    Manage your cash flow on a pay-per-swap basis or buy a bundled swap package.</p>
                </div>
              </div>
              <div class="count-content-wrapper">
                <h3 class="h3-count">02</h3>
                <div class="count-content-block">
                  <h4 class="h4">Swap &amp; Go Locations</h4>
                  <p>See battery availability at every swap station and plan your routes.</p>
                </div>
              </div>
              <div class="count-content-wrapper">
                <h3 class="h3-count">03</h3>
                <div class="count-content-block">
                  <h4 class="h4">Fleet Management </h4>
                  <p>Customers that use vehicles powered by No.1 Pile, manage your entire fleet from the No.1 Pile Fleet Platform.
                    From managing your riders' Swap &amp; Go access to tracking your fleet purchases and running costs.
                  </p>
                </div>
              </div>
            </div>
            <div>Already have a No.1 Pile account?</div><a
              href="https://play.google.com/store/apps/details?id=com.anzuo.ibrs4iphone" target="_blank"
              class="button download w-button">Download</a>
          </div>

          <div class="application-offgrid dashboard"></div>
        </div>
      </div>
    </section>
    <section class="section top-minus-50">
      <div class="w-layout-blockcontainer container w-container">
        <div class="discover-wraper"><img
            src="@/assets/image4.png"
            loading="lazy" alt="" sizes="(max-width: 479px) 94vw, (max-width: 991px) 96vw, 98vw"
            class="discover-img">
          <div class="discover-overlay"></div>
          <div class="discover-content-wrapper">
            <p>No.1 Pile Energy Solutions &amp; Applications</p>
            <h2 class="h2 linkback-margin-top">See how our solutions can power your productivity with multiple
              applications, powered by No.1 Pile batteries.</h2><a href="/applications"
              class="button yellow discover w-button">Applications</a>
          </div>
          <div class="artifact-vertical-line discover"></div>
          <div class="artifact-horizontal-line discover"></div>
          <div></div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Carousel from "@/components/Carousel.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    Carousel,
    Footer,
  },
};
</script>

<style scoped>
:root {
  --system--alt-section: #f0f1f3;
  --element--black: black;
  --boarder-radius: 5px;
  --system--white: white;
  --element--line: rgba(0, 0, 0, .07);
  --element--product: #353535;
  --element--yellow: #ffa000;
  --font--primary: #000;
  --hero-h1: Everett, sans-serif;
  --hero-h2: Everett, sans-serif;
  --system--alt-section-v2: #e2e4e9;
  --element--white: white;
  --section--margins: 50px;
  --font--white: white;
  --section--content-height: 750px;
  --section-h1: Everett, sans-serif;
  --font--secondary: rgba(0, 0, 0, .75);
  --button--yellow: #ffa000;
  --button--black: black;
  --button--white: white;
  --element--jidi-brand: #e4161c;
  --grid-gad: 50px;
  --button--white-outline: rgba(0, 0, 0, .25);
  --overlay: rgba(0, 0, 0, .5);
  --hero-overlay: rgba(0, 0, 0, .2);
  --system--grey: rgba(0, 0, 0, .07);
}

.home-container {
  /* 你的CSS样式 */
}

.section {
  /* background: burlywood; */
  padding-top: 50px;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
}

.introduction {
  /* 你的CSS样式 */
}

h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 32px;
  font-weight: 400;
  line-height: 36px;
}

h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.h4 {
  font-family: Everett, sans-serif;
  margin-bottom: 5px;
  font-size: 18px;
}

.h4.base-12-5 {
  margin-bottom: 12.5px;
}

p {
  opacity: .65;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.5em;
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}


.content-wrapper {
  margin-bottom: 50px;
}


.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.w-container {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  width: 98%;
  max-width: 1490px;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}


.grid-solutions-about {
  min-height: 750px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-radius: 5px;
  background-color: #f0f1f3;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
}


.grid-block.solutions {
  background-color: #e2e4e9;
  flex-direction: row;
  display: flex;
  position: relative;
}

.solutions-content-column {
  width: 100%;
}

.solutions-content-wrapper {
  width: 74%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 70px 120px;
  display: flex;
}

.artifact-vertical-line {
  width: 1px;
  height: 100%;
  mix-blend-mode: overlay;
  background-color: #000;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 100px;
  right: auto;
  box-shadow: 1px 0 0 0.5px #fff;
}

.solutions-battery-img {
  width: 100%;
  min-width: 115%;
  object-position: 100% 50%;
  margin-left: -10%;
}

.artifact-vertical-line.null {
  display: none;
}

.artifact-vertical-line.discover {
  opacity: 0.1;
  left: 50px;
}

.artifact-vertical-line.swap-solutions {
  left: 75px;
  box-shadow: 1px 0 0 0.5px rgba(255, 255, 255, 0.35);
}

.artifact-vertical-line.swap-solutions.img {
  display: none;
  box-shadow: 1px 0 0 0.5px rgba(255, 255, 255, 0.25);
}

.breakout-banner.solutiions-design {
  min-height: 400px;
}

.breakout-banner.solutiions-design {
  background-image: url("/public/image1.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.content-wrapper {
  margin-bottom: 50px;
}


.container.battery-pack {
  border-radius: 5px;
  background-color: #353535;
  overflow: hidden;
}

.grid-swap-and-go {
  min-height: 750px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
}

.solutions-swap-cont-wrapper {
  /* width: 50%; */
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 125px 125px 100px 100px;
  display: flex;
  position: relative;
}

.count-content-wrapper {
  width: 100%;
  margin-top: 25px;
  display: flex;
}

.breakout-header-copy {
  /* width: 100%; */
  /* height: 50px; */
  color: rgba(255, 255, 255, .6);
  align-items: center;
  padding: 15px 50px;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.h3-count {
  opacity: .15;
  margin-right: 25px;
  font-size: 34px;
  line-height: 34px;
}

.swap-banner {
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 100px;
  display: flex;
  position: relative;
}

.solutions-kore1-block {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  position: relative;
}

.spec-base-wrapper {
  padding: 30px 50px 25px;
  position: relative;
}

.grid-swap-spec {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

.spec-title {
  margin-bottom: 5px;
}

.spec-sub-title {
  opacity: .5;
}

.artifact-horizontal-line {
  height: 1px;
  mix-blend-mode: overlay;
  background-color: #000;
  position: absolute;
  top: 100px;
  bottom: auto;
  left: 0%;
  right: 0%;
  box-shadow: 0 1px 0 .5px #fff;
}

.artifact-horizontal-line.battery-label {
  opacity: .2;
  top: 50px;
}

.artifact-vertical-line.battery-img {
  opacity: .2;
  left: 0;
}

.artifact-horizontal-line.battery-stats {
  opacity: .2;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.section.top-minus-50 {
  padding-top: 0;
}

.grid-solutions-tech {
  min-height: 750px;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  grid-template-rows: auto;
}

.grid-block.app {
  border-radius: 5px;
  background-color: #ffa000;
  color: #000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 75px 100px 100px 75px;
  display: flex;
}

.application-offgrid {
  min-height: 700px;
  border-radius: 5px;
  color: white;
  background-color: #313131;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px;
  display: flex;
}

.application-offgrid.dashboard {
  border-radius: 5px;
  background-image: url(/public/image2.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.discover-wraper {
    min-height: 500px;
    padding: 100px 50% 50px 100px;
    border-radius: 5px;
    background-color: #000000;
    color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    position: relative;
    overflow: hidden;
}
.discover-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}
.discover-overlay {
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.w-button {
    color: #fff;
    line-height: inherit;
    cursor: pointer;
    background-color: #3898ec;
    border: 0;
    border-radius: 0;
    padding: 9px 15px;
    text-decoration: none;
    display: inline-block;
}

.button {
    background-color: #000000;
    border-radius: 50px;
    padding: 10px 20px;
    transition: color .15s, background-color .15s;
}

.discover-content-wrapper {
    position: relative;
}
.h2.linkback-margin-top {
    margin-top: 50px;
}

.button.yellow {
    background-color: #ffa000;
    color: #000;
}
.button.yellow.breakout, .button.yellow.discover {
    margin-top: 25px;
}

.artifact-vertical-line {
    width: 1px;
    height: 100%;
    mix-blend-mode: overlay;
    background-color: #000;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 100px;
    right: auto;
    box-shadow: 1px 0 0 .5px #fff;
}

.artifact-vertical-line.discover {
    opacity: .1;
    left: 50px;
}

.artifact-horizontal-line {
    height: 1px;
    mix-blend-mode: overlay;
    background-color: #000;
    position: absolute;
    top: 100px;
    bottom: auto;
    left: 0%;
    right: 0%;
    box-shadow: 0 1px 0 .5px #fff;
}

.artifact-horizontal-line.discover {
    opacity: .1;
    top: 50px;
}

@import '@/style/home.css';
</style>
