import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
// 导入其他视图组件

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history', // 确保使用history模式以支持无刷新路由
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/home' // 重定向到 '/home'
    },
    {
      path: '/home',
      name: 'Home',
      component: Home
    },
    // 其他页面的路由...
  ],
  linkExactActiveClass: 'active' // 你可以定义一个激活链接的CSS类
});

// const router = new VueRouter({
//     routes
//   })
  
export default router